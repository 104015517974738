// import { responsiveFontSizes, createTheme } from '@mui/material/styles';
import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
export const CustomTheme = (lang) => {
  // TODO: all these colors should be in a centralized variables.
  const theme = extendTheme({
    direction: lang === 'ar' ? 'rtl' : 'ltr',
    colorSchemes: {
      light: {
        palette: {
          secondary: { main: '#48A9C5' }, //for pagination
          info: { main: '#fff' }, // for select in mobile drop
          brand: {
            primaryDark: '#BF3B34',
            primaryMain: '#F04B4B',
            primaryLight: '#E58977',
            secondaryDark: '#48A9C5',
            secondaryMain: '#5CC9E6',
            secondaryLight: '#A1D7EA',
          },
          grey: {
            white: '#FFFFFF',
            light: '#F4EFF4',
            regular: '#C9C5CA',
            neutral: '#939094',
            dark: '#484649',
            black: '#000000',
          },
          admin: {
            // TODO: The below needs to be fixed, remove unnecessary variable, rename to more suitable variable names.
            main: '#F64E60',
            button: 'rgba(246, 78, 96, 0.75)',
            sideMenuIcons: '#464E5F',
            sideMenuTextColor: 'rgba(0, 0, 0, 0.87)',
            background: '#F3F6F9',
            header: '#5CC9E6',
            black: '#3F4254',
            headerColor: '#5CC9E6',
            footerColor: '#F2F2F2',
            main: '#f64e60bf',
            icons: '#464E5F',
            texts: '#3F4254',
            secondaryTexts: '#0000007a',
            links: '#1976D2',
            danger: '#F64E6066',
            cardBorder: '#00000033',
            green: '#64B161',
            lightGreen: '#64B16180',
            red: '#F64E6080',
            grey: '#B7B7B7',
            lightGrey: '#B7B7B780',
          },
          others: {
            orange: '#FF7629',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: 'Avenir',
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '6.25rem',
            padding: '0.625rem 1.875rem',
            fontSize: '1rem',
            fontWeight: '900',
            lineHeight: '1.75rem',
            textTransform: 'none',
          },
        },
        variants: [
          {
            props: { variant: 'main' },
            style: {
              color: '#fff',
              backgroundColor: '#48A9C5',
              '&:hover': {
                backgroundColor: '#48A9C5',
              },
            },
          },
          {
            props: { variant: 'cancel' },
            style: {
              color: '#000',
              backgroundColor: '#E58977',
              '&:hover': {
                backgroundColor: '#E58977',
              },
            },
          },
          {
            props: { variant: 'upload' },
            style: {
              backgroundColor: '#F4EFF4',
              '&:hover': {
                backgroundColor: '#F4EFF4',
              },
            },
          },
          {
            props: { variant: 'adminMain' },
            style: {
              color: '#fff',
              fontSize: '0.9375rem',
              backgroundColor: '#f64e60bf',
              borderRadius: '9px',
              lineHeight: '1.625rem',
              letterSpacing: '0.46px',
              fontWeight: '400',
              padding: '0.375rem 1.75rem',
              '&:hover': {
                backgroundColor: '#f64e60bf',
              },
            },
          },
          {
            props: { variant: 'adminExport' },
            style: {
              padding: 0,
              color: '#F64E60BF',
              borderRadius: '2px',
              fontSize: '14px',
              fontWeight: '400',
              textTransform: 'uppercase',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '&.Mui-error': {
                '&:hover fieldset': {
                  borderColor: '#d32f2f',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d32f2f',
                },
              },
              '& fieldset': {
                borderColor: '#C9C5CA',
                borderWidth: '1px',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#C9C5CA',
                borderWidth: '1px',
              },
              '&:hover fieldset': {
                borderColor: '#C9C5CA',
              },
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              '&:hover': {
                '&& fieldset': {
                  borderColor: '#d32f2f',
                },
              },
              '&.Mui-focused': {
                '&& fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            },
            '&:hover': {
              '&& fieldset': {
                borderColor: '#C9C5CA',
              },
            },
            '&.Mui-focused': {
              '&& fieldset': {
                borderColor: '#C9C5CA',
                borderWidth: '1px',
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              color: 'black',
              opacity: '1',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#939094',
            opacity: '1',
            '&.Mui-error': {
              '&.Mui-focused': {
                color: '#d32f2f',
              },
            },
            '&.Mui-focused': {
              color: '#939094',
            },
          },
          asterisk: {
            display: 'none',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            color: 'inherit',
            fontFamily: 'inherit',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#C9C5CA',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#C9C5CA',
              borderWidth: '1px',
            },
          },
        },
      },
    },
  });
  return theme;
};
