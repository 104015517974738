import { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DiningOutlinedIcon from '@mui/icons-material/DiningOutlined';
import DeliveryDiningSharpIcon from '@mui/icons-material/DeliveryDiningSharp';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FeaturedVideoRoundedIcon from '@mui/icons-material/FeaturedVideoRounded';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import style from '../../styles/admin/style.module.css';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import usePermission from '../../services/utilities/usePermissions';
import useFetch from '../../services/utilities/useFetch';
import { getExternalLinksApi } from '../../services/utilities/apiConfig';

const drawerWidth = 365;
const iconStyle = { width: '24px', height: '24px', color: 'inherit' };
const sideItem = [
  {
    id: 0,
    text: 'Dashboard',
    enabled: true,
    link: '/',
    permissions: [
      'Kitchefy',
      'Full Partner',
      'Fulfillment Partner',
      'Brand Partner',
      'Sampling',
    ],
    icon: <DashboardOutlinedIcon />,
  },
  {
    id: 1,
    text: 'sideBar.firms',
    enabled: true,
    link: '/firms',
    permissions: 'Kitchefy',
    icon: <ApartmentIcon sx={iconStyle} />,
  },
  {
    id: 2,
    text: 'Groups and Kitchens',
    enabled: true,
    link: '/groups',
    permissions: ['Kitchefy', 'Full Partner', 'Fulfillment Partner'],
    icon: <StorefrontOutlinedIcon sx={iconStyle} />,
  },
  {
    id: 3,
    text: 'sideBar.brands',
    enabled: true,
    icon: <FastfoodOutlinedIcon sx={iconStyle} />,
    permissions: ['Kitchefy', 'Full Partner', 'Brand Partner', 'Sampling'],
    link: '/brands',
  },
  {
    id: 4,
    text: 'sideBar.equipment',
    icon: <RestaurantMenuOutlinedIcon sx={iconStyle} />,
    permissions: 'Kitchefy',
    enabled: true,
    link: '/equipment',
  },
  {
    id: 5,
    text: 'sideBar.menu_items',
    icon: <DiningOutlinedIcon sx={iconStyle} />,
    enabled: true,
    permissions: ['Kitchefy', 'Full Partner', 'Brand Partner', 'Sampling'],
    link: '/menu-items',
  },
  {
    id: 6,
    text: 'sideBar.Channels',
    icon: <DeliveryDiningSharpIcon sx={iconStyle} />,
    enabled: true,
    permissions: 'Kitchefy',
    link: '/channels',
  },
  {
    id: 7,
    text: 'sideBar.suppliers',
    icon: <LocalShippingIcon sx={iconStyle} />,
    enabled: true,
    permissions: 'Kitchefy',
    link: '/suppliers',
  },
  {
    id: 8,
    text: 'Supplies',
    icon: <ShoppingCartOutlinedIcon sx={iconStyle} />,
    enabled: true,
    permissions: 'Kitchefy',
    link: '/supplies',
  },
  {
    id: 9,
    text: 'Menu Components',
    icon: <WorkspacesIcon sx={iconStyle} />,
    enabled: true,
    permissions: 'Kitchefy',
    link: '/menu-components',
  },
  {
    id: 10,
    text: 'Training Materials',
    icon: <FeaturedVideoRoundedIcon sx={iconStyle} />,
    enabled: true,
    permissions: [
      'Kitchefy',
      'Full Partner',
      'Brand Partner',
      'Fulfillment Partner',
    ],
    link: '/training-materials',
  },
];

export const SideBar = () => {
  const { t } = useTranslation('admin', 'titleTabs', 'common');

  const Router = useRouter();
  const [getFetch] = useFetch();

  const [selected, setSelected] = useState();

  const [hasPermission] = usePermission();

  const [externalLinks, setExternalLinks] = useState([]);

  function getExternalLinks() {
    const response = getFetch(getExternalLinksApi).then((response) => {
      setExternalLinks(response?.data);
    });
  }

  // The below block of code is responsible to keep track of the scroll position to calculate the position of scroll, to maintain the height of the side bar (whether we are subtracting only header height, or header and footer height) - Height size: 71px , footer size: 65px
  const [endOfPage, setEndOfPage] = useState(true);

  function handleScrollPosition() {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      setEndOfPage(true);
    } else {
      setEndOfPage(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollPosition);
    return () => {
      window.removeEventListener('scroll', handleScrollPosition);
    };
  }, []);

  useEffect(() => {
    getExternalLinks();
    const currentPath = Router.pathname;
    const thirdSlashIndex = currentPath.indexOf(
      '/',
      currentPath.indexOf('/') + 1
    );
    const extractedUrl =
      thirdSlashIndex !== -1
        ? currentPath.substring(0, thirdSlashIndex)
        : currentPath;

    for (const items of sideItem) {
      if (extractedUrl === items?.link) {
        setSelected(items?.id);
        break;
      }
    }
  }, []);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          position: 'fixed',
          top: '71px',
          height: `${endOfPage ? 'calc(100vh - 136px)' : 'calc(100vh - 71px)'}`,
          overflow: 'auto',
          boxShadow: 'none',
          width: drawerWidth,
          boxSizing: 'border-box',
          zIndex: '1',
        },
      }}
      variant='permanent'
      anchor='left'
      PaperProps={{
        sx: {
          border: 'none',
          boxShadow: '0px 0px 3px #cdcdcd',
        },
      }}
    >
      <Box sx={{ padding: '2.0625rem 0rem 0rem 1.63rem' }}>
        {/* Internal Sections */}
        {sideItem?.map((section) => (
          <Box key={section?.id}>
            <List sx={{ padding: '0' }}>
              {hasPermission(section?.permissions) && (
                <ListItem key={section.text} disablePadding>
                  <Link
                    href={`${section?.link}`}
                    style={{
                      width: '100%',
                      cursor: `${section?.link || 'default'}`,
                      pointerEvents: `${section?.link || 'none'}`,
                    }}
                    prefetch={false}
                    disabled
                  >
                    <ListItemButton
                      disabled={!section?.enabled || false}
                      onClick={() => setSelected(section.id)}
                      sx={{
                        padding: '.8rem',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            section.id == selected
                              ? 'admin.button'
                              : 'admin.sideMenuIcons',
                        }}
                      >
                        {section.icon}
                      </ListItemIcon>

                      <ListItemText
                        primary={t(section.text)}
                        primaryTypographyProps={{
                          fontWeight: '300',
                          color:
                            section.id == selected
                              ? 'admin.button'
                              : 'admin.sideMenuTextColor',
                        }}
                        className={style.drawerItemText}
                      />
                      <KeyboardArrowRightIcon
                        sx={{
                          fontSize: '24px',
                          color:
                            section.id == selected
                              ? 'admin.button'
                              : 'admin.sideMenuIcons',
                          transform:
                            Router.locale == 'ar' ? 'rotate(180deg)' : '',
                        }}
                      />
                    </ListItemButton>
                  </Link>
                </ListItem>
              )}
            </List>
          </Box>
        ))}
        {/* External Links */}
        <Divider sx={{ margin: '1rem 2rem 1rem 1rem' }} />
        {externalLinks?.map((section) => (
          <Box key={section?.id}>
            <List sx={{ padding: '0' }}>
              <ListItem disablePadding>
                <Link
                  href={`${section?.url}`}
                  target='_blank'
                  style={{
                    width: '100%',
                    cursor: `pointer`,
                  }}
                  prefetch={false}
                  disabled
                >
                  <ListItemButton
                    sx={{
                      padding: '.8rem',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: 'admin.sideMenuIcons',
                      }}
                    >
                      <OpenInNewRoundedIcon />
                    </ListItemIcon>

                    <ListItemText
                      primary={t(section?.name)}
                      primaryTypographyProps={{
                        fontWeight: '300',
                        color: 'admin.sideMenuTextColor',
                      }}
                      className={style.drawerItemText}
                    />
                    <KeyboardArrowRightIcon
                      sx={{
                        fontSize: '24px',
                        color: 'admin.sideMenuIcons',
                        transform:
                          Router.locale == 'ar' ? 'rotate(180deg)' : '',
                      }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
          </Box>
        ))}
      </Box>
    </Drawer>
  );
};
