import { Avatar, Typography } from '@mui/material';
import Image from 'next/image';
import { extractFirstLetters } from '../../services/utilities/utils';

export const AvatarModal = ({
  image,
  name,
  height = '2.2rem',
  width = '2.2rem',
  variant,
}) => {
  const [firstLetter, secondLetter] = extractFirstLetters(name);
  return (
    <>
      <Avatar
        variant={variant}
        sx={{
          height: height,
          width: width,
          boxShadow: '0px 0px 1px grey',
        }}
      >
        {image ? (
          <Image
            alt='Kitchen logo image'
            src={image}
            fill
            priority
            sizes='(min-width: 10%), (min-height: 10%)'
            style={{
              objectFit: 'contain',
              position: 'absolute',
            }}
          />
        ) : (
          <Typography sx={{ fontSize: { xs: '5px', md: '10px' } }}>
            {firstLetter} {secondLetter}
          </Typography>
        )}
      </Avatar>
    </>
  );
};
