import { useRouter } from 'next/router';
import axiosInstance from './axiosConfig';
import { alertsSlice } from '../redux-toolkit/slices/alertsSlice';
import { store } from '../redux-toolkit/store';

function handleError(response, setFieldError) {
  const responseCode = response?.response?.status;
  if (responseCode === 400 || responseCode === 401) {
    const errorResponseData = response?.response?.data || {};
    if (setFieldError) {
      Object.entries(errorResponseData)?.map(([key, value]) => {
        setFieldError(key, value[0]);
      });
    } else {
      store.dispatch(
        alertsSlice.actions.errorWithMessage({
          error: true,
          message: Object.values(errorResponseData)[0],
        })
      );
    }
  } else if (responseCode >= 500 && responseCode <= 599) {
    store.dispatch(
      alertsSlice.actions.errorWithMessage({
        error: true,
        code: 500,
      })
    );
  } else {
    store.dispatch(alertsSlice.actions.triggerError());
  }
}

function useFetch() {
  const Router = useRouter();
  async function getFetch(url) {
    try {
      const response = await axiosInstance.get(url, {
        headers: { 'accept-Language': Router.locale },
      });
      if (response.status == 200) return Promise.resolve(response);
      else {
        handleError(response);
        return Promise.reject('');
      }
    } catch (error) {
      handleError(error);
      return Promise.reject('');
    }
  }
  /////
  async function patchFetch(
    url,
    newBody = {},
    oldBody = {},
    setFieldError = null,
    header = {}
  ) {
    let body = compareBody(newBody, oldBody);
    if (
      !(body instanceof FormData) &&
      typeof body === 'object' &&
      Object.keys(body).length === 0
    ) {
      let response = { status: 200, data: { ...newBody } };
      return Promise.resolve(response);
    } else {
      try {
        const response = await axiosInstance.patch(url, body, {
          headers: { 'accept-Language': Router.locale, ...header },
        });
        if (response.status == 200 || response.status == 201)
          return Promise.resolve(response);
        else {
          handleError(response, setFieldError);
          return Promise.reject('');
        }
      } catch (error) {
        handleError(error, setFieldError);
        return Promise.reject('');
      }
    }
  }
  ////
  async function postFetch(url, body = {}, setFieldError = null, header = {}) {
    try {
      const response = await axiosInstance.post(url, body, {
        headers: { 'accept-Language': Router.locale, ...header },
      });
      if (response?.status == 200 || response?.status == 201)
        return Promise.resolve(response);
      else {
        handleError(response, setFieldError);
        return Promise.reject('');
      }
    } catch (error) {
      handleError(error, setFieldError);
      return Promise.reject('');
    }
  }

  //
  async function deleteFetch(url, header = {}) {
    try {
      const response = await axiosInstance.delete(url, {
        header: { 'accept-Language': Router.locale, ...header },
      });
      if (response?.status == 200 || response?.status == 204) {
        return Promise.resolve(response);
      }
    } catch (error) {
      handleError(error);
      return Promise.reject('');
    }
  }

  function compareBody(newBody, oldBody) {
    let body = {};
    if (newBody instanceof FormData) {
      // The below "bodyCount" variable is used to check if the form data length is zero we just return an empty object at the end, so we don't send a request, also to avoid looping over the form data again to see if it's empty or not.
      let bodyCount = 0;
      body = new FormData();
      for (const [key, newValue] of newBody.entries()) {
        if (newValue == 'null') {
          body.append(key, '');
          bodyCount++;
        } else if (oldBody[key]?.toString() !== newValue) {
          body.append(key, newValue);
          bodyCount++;
        }
      }
      if (bodyCount == 0) {
        body = {};
      }
    } else {
      for (const [key, newValue] of Object.entries(newBody)) {
        if (typeof oldBody[key] == 'object' && oldBody[key] != null) {
          if (JSON.stringify(oldBody[key]) !== JSON.stringify(newValue)) {
            body[key] = newValue;
          }
        } else if (oldBody[key] !== newValue) {
          body[key] = newValue;
        }
      }
    }
    return body;
  }

  return [getFetch, postFetch, patchFetch, deleteFetch];
}
export default useFetch;
