import { useState } from 'react';
import { Avatar, Box, Container, Typography } from '@mui/material';
import Link from 'next/link';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { HeaderGeneralMenu } from './headerGeneralMenu';
import { useSelector } from 'react-redux';
import style from '../../../styles/headFooter.module.css';
import { useTranslation } from 'next-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { MobileHeader } from './Mobile/mobileHeader';
import { HeaderSettingsMenu } from './headerSettingsMenu';
import useChangeLanguage from '../../../services/utilities/useChangeLanguage';
import { HeaderKitchInOut } from './headerKitchInOut';
///
export const Header = () => {
  const { t } = useTranslation('incidents', 'common');
  const [anchorElLang, setAnchorElLang] = useState();
  // const [anchorElInced, setAnchorElInced] = useState();
  const [anchorElSettings, setAnchorElSettings] = useState();
  const [openMobileMenu, setOpenMobileMenu] = useState();
  const user = useSelector((state) => state.userSlice.info);
  const kitchens = useSelector((state) => state.kitchenSlice.info);
  // const permissions = useSelector((state) => state.kitchenSlice.permissions);
  const [changeLanguage] = useChangeLanguage();
  const Router = useRouter();
  const handleButtonClick = (event, setAnchorEl) => {
    setAnchorEl(event?.currentTarget);
  };
  const languages = [
    { name: 'English', value: 'en' },
    { name: 'العربية', value: 'ar' },
  ];
  const Kitchens = kitchens?.map((item) => ({
    id: item.kitchen.id,
    name: item.kitchen.name,
    value: item.kitchen.logo,
  }));

  return (
    <Box sx={{ backgroundColor: 'brand.secondaryDark' }}>
      <Container maxWidth='lg' sx={{ height: '71px' }}>
        <Box className={style.headFlex}>
          <Box
            className={style.headList}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'space-between', md: 'flex-start' },
              flexGrow: 2,
              gap: '4vw',
            }}
          >
            <Link href='/quality-management/'>
              <Image
                alt=''
                src='/images/headTitle.png'
                width={134}
                height={31}
                priority
              />
            </Link>
            <>
              <MenuIcon
                fontSize='large'
                sx={{
                  display: { xs: 'block', md: 'none' },
                  color: 'grey.white',
                }}
                onClick={(e) => handleButtonClick(e, setOpenMobileMenu)}
              />
              <Box
                className={style.headList}
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  gap: { md: '2vw', lg: '4vw' },
                }}
              >
                <Link href='/quality-management' prefetch={false}>
                  <Typography
                    className={style.headFont}
                    sx={{ color: 'grey.white' }}
                  >
                    {t('common:my_kitchen')}
                  </Typography>
                </Link>
                <Link href='/quality-management/incidents' prefetch={false}>
                  <Typography
                    className={style.headFont}
                    sx={{ color: 'grey.white' }}
                  >
                    {t('common:incidents')}
                  </Typography>
                </Link>
                <Link href='/quality-management/checklists' prefetch={false}>
                  <Typography
                    className={style.headFont}
                    sx={{ color: 'grey.white' }}
                  >
                    {t('common:checklists')}
                  </Typography>
                </Link>
                <Link href='/quality-management/kitchios' prefetch={false}>
                  <Typography
                    className={style.headFont}
                    sx={{ color: 'grey.white' }}
                  >
                    {t('common:kitch_in_out')}
                  </Typography>
                </Link>
              </Box>
            </>
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MobileHeader
              Kitchens={Kitchens}
              languages={languages}
              anchorEl={openMobileMenu}
              setAnchorEl={setOpenMobileMenu}
            />
          </Box>
          <Box
            className={`${style.headList} ${style.headBox}`}
            sx={{
              color: 'grey.white',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
                display: { xs: 'none', md: 'flex' },
              }}
              className={style.headList}
              onClick={(e) => handleButtonClick(e, setAnchorElLang)}
            >
              <Typography className={style.headFont}>
                {Router.locale}
              </Typography>
              <ArrowDropDownIcon fontSize='small' />
            </Box>
            <HeaderKitchInOut />
            <HeaderGeneralMenu
              data={languages}
              handleFunction={changeLanguage}
              anchorEl={anchorElLang}
              setAnchorEl={setAnchorElLang}
            />
            <Box
              onClick={(e) => handleButtonClick(e, setAnchorElSettings)}
              sx={{
                cursor: 'pointer',
                display: { xs: 'none', md: 'flex' },
              }}
              className={style.headList}
            >
              <Avatar
                sx={{
                  width: 41,
                  height: 41,
                  backgroundColor: 'inherit',
                }}
                src={
                  user?.profile?.image?.length > 0 ? user?.profile?.image : null
                }
              >
                <Typography
                  className={style.nameStyle}
                  sx={{
                    color: 'brand.secondaryDark',
                    backgroundColor: 'grey.white',
                  }}
                >
                  {user?.first_name.slice(0, 1)}
                  {user?.last_name.slice(0, 1)}
                </Typography>
              </Avatar>
            </Box>
            <HeaderSettingsMenu
              list={Kitchens}
              anchorEl={anchorElSettings}
              setAnchorEl={setAnchorElSettings}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
