import axios from 'axios';
import Cookies from 'js-cookie';
import { refreshTokenApi } from './apiConfig';
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = Cookies.get('accessToken');
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'accept-Language':
        config.headers['accept-Language'] === undefined
          ? 'en'
          : config.headers['accept-Language'],
      'Content-Type':
        config?.headers['Content-Type'] === undefined
          ? 'application/json'
          : config.headers['Content-Type'],
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      axiosInstance.defaults.headers.common['Authorization'] =
        'Bearer ' + access_token;
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refresh = Cookies.get('refreshToken');
  const newAccessToken = await axiosInstance
    .post(refreshTokenApi, {
      refresh,
    })
    .then(function (response) {
      return response?.data?.access;
    })
    .catch(function (error) {
      console.log(error);
    });
  Cookies.set('accessToken', newAccessToken, {
    path: '/',
    secure: true,
  });
};
export default axiosInstance;
