import { Alert, AlertTitle, Box, Slide } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
export default function AlertMessage({
  alertType,
  alertMessage = null,
  code = null,
}) {
  const { t } = useTranslation('common');
  const [transition, setTransition] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setTransition(false);
    }, 1500);
  });

  const defaultMessage =
    alertType === 'success' ? t('success_message') : t('something_went_wrong');
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          width: '25%',
          bottom: '100px',
          right: '10px',
          zIndex: '2000',
        }}
      >
        <Slide
          direction='left'
          in={transition}
          easing={{
            enter: 'cubic-bezier(0, 1.5, .8, 1)',
            exit: 'linear',
          }}
          mountOnEnter
          unmountOnExit
        >
          <Alert severity={alertType}>
            <AlertTitle>{t(`${alertType}`)}</AlertTitle>
            {alertMessage || (code ? t(`alert_codes.${code}`) : defaultMessage)}
          </Alert>
        </Slide>
      </Box>
    </>
  );
}
