export const baseURL = process.env.NEXT_PUBLIC_BACKEND_ENDPOINT;

// Utils
export const refreshTokenApi = `${baseURL}/utils/authentication/refresh/`;
export const tokenApi = `${baseURL}/utils/authentication/token/`;

//User
export const getUserApi = `${baseURL}/users/me/`;

// Incidents
export const getResolveActionsApi = `${baseURL}/incidents/actions/`;
///get Incidents
export const getIncidentsApi = (kitch, page = 1, isRes, query) =>
  `${baseURL}/incidents/incidents/?is_resolved=${isRes}&kitchen=${kitch}&page=${page}${query}`;
export const getIncidentDetails = (id) =>
  `${baseURL}/incidents/incidents/${id}/`;
export const postCustomerFeedbackApi = (uuid) =>
  `${baseURL}/incidents/incidents/customer-feedback/${uuid}/submit/`;
export const getCustomerIncidentDetails = (uuid) =>
  `${baseURL}/incidents/incidents/customer-incident-details/${uuid}`;
export const editCustomerFeedbackApi = (id) => ``;
export const newIncidentApi = `${baseURL}/incidents/incidents/`;
export const getIncidentApi = (id) => `${baseURL}/incidents/incidents/${id}/`;
export const editIncidentApi = (id) => `${baseURL}/incidents/incidents/${id}/`;
export const getTypesApi = `${baseURL}/incidents/types/`;
export const resolveIncidentApi = (id) =>
  `${baseURL}/incidents/incidents/${id}/resolve/`;
export const notifyManagerApi = (id) =>
  `${baseURL}/incidents/incidents/${id}/notify_managers/`;
export const requestCustomerFeedbackApi = (id) =>
  `${baseURL}/incidents/incidents/${id}/request_customer_feedback/`;

//Checklists
export const getChecklistDetailsApi = (uuid) =>
  `${baseURL}/checklists/checklists/${uuid}/`;
export const getChecklistResponsesApi = (checklist_uuid, response_uuid) =>
  `${baseURL}/checklists/checklists/${checklist_uuid}/responses/${response_uuid}/`;
export const ChecklistResponseReviewApi = (item_id, id) =>
  `${baseURL}/checklists/items/${item_id}/responses/${id}/review/`;
export const getContentDetailsApi = (uuid) =>
  `${baseURL}/checklists/checklists/${uuid}/structure/`;
export const getChecklistsApi = (kitchenId) =>
  `${baseURL}/checklists/checklists/?kitchen=${kitchenId}`;
export const getChecklistsResponses = (uuid, page, query) =>
  `${baseURL}/checklists/checklists/${uuid}/responses/?page=${page}${query}`;
export const getChecklistResponse = (uuid) =>
  `${baseURL}/checklists/checklists/${uuid}/`;
export const getChecklistStructure = (uuid) =>
  `${baseURL}/checklists/checklists/${uuid}/structure/`;
export const startChecklistResponse = (uuid) =>
  `${baseURL}/checklists/checklists/${uuid}/responses/`;
export const finishChecklistResponse = (checklistUUID, startUUID) =>
  `${baseURL}/checklists/checklists/${checklistUUID}/responses/${startUUID}/finish/`;
export const addChecklistItemResponse = (id) =>
  `${baseURL}/checklists/items/${id}/responses/`;
export const updateChecklistItemResponse = (id, startUUID) =>
  `${baseURL}/checklists/items/${id}/responses/${startUUID}/`;

// KitchIO
export const postKitchIOLocationReview = (kitchIOUUID) =>
  `${baseURL}/kitchios/kitchios/${kitchIOUUID}/review/`;

export const getKitchIOResponseDetailsAPI = (checklistUUID) =>
  `${baseURL}/kitchios/kitchios/${checklistUUID}/`;
// Kitchens
export const getKitchen = (id) => `${baseURL}/kitchens/kitchens/${id}/`; ///kitchen's details
export const getKitchensApi = `${baseURL}/users/me/kitchens/`;
export const getKitchenEmployeesApi = (kitchenId, kitched_in = false) =>
  `${baseURL}/kitchens/kitchens/${kitchenId}/employees/?mark_kitched_in=${kitched_in}`;
export const getSubSectionsApi = (kitchenId) =>
  `${baseURL}/kitchens/subsections/?kitchen=${kitchenId}`;
export const getBrandsApi = (kitchenId) =>
  `${baseURL}/kitchens/kitchens/${kitchenId}/brands/`;
export const getKitchenTypesApi = `${baseURL}/kitchens/types/`;

// Channels
export const getChannels = (id) =>
  `${baseURL}/channels/channels/?kitchen=${id}`;

//Language
export const UpdateLanguage = `${baseURL}/users/me/language/`;

///Footer
export const getSupport = `${baseURL}/utils/data/contact-us/`;
export const getServerDate = `${baseURL}/utils/data/server-datetime/`;
export const getExternalLinksApi = `${baseURL}/external-links/links/`;

///KitchIO
export const getKitchInOutResponses = (kitchenID, page, query) =>
  `${baseURL}/kitchios/kitchios/?kitchen=${kitchenID}&page=${page}${query}`;

export const postKitchInOutRange = `${baseURL}/kitchios/out_of_range/`;

export const postKitchInOut = `${baseURL}/kitchios/kitchios/`;

export const sendKitchInChecklist = (uuid) =>
  `${baseURL}/kitchios/kitchios/${uuid}/`;

///My Kitchen Page
export const incidentStatistics = (id, startDate, endDate, distribution) =>
  `${baseURL}/kitchens/kitchens/${id}/incidents_statistics/?start_datetime=${startDate}&end_datetime=${endDate}&include_distribution=${distribution}`;

///brands
export const getBrands = (id, active = false) =>
  `${baseURL}/kitchens/kitchens/${id}/brands/?active_only=${active}`;

///statistics
export const checklist_statistics = (id, startDate, endDate) =>
  `${baseURL}/kitchens/kitchens/${id}/checklists_statistics/?start_datetime=${startDate}&end_datetime=${endDate}`;

export const kitchIo_statistics = (id, startDate, endDate) =>
  `${baseURL}/kitchens/kitchens/${id}/kitchios_statistics/?start_datetime=${startDate}&end_datetime=${endDate}`;

///Data Hub

// Supplier
export const postSupplier = `${baseURL}/suppliers/suppliers/`;
export const getSupplier = (id) => `${baseURL}/suppliers/suppliers/${id}/`;
export const getSupplierCoveredCountries = (id) =>
  `${baseURL}/suppliers/suppliers/${id}/countries_covered_areas_count/`;
export const getSupplierCoveredTerritories = (id) =>
  `${baseURL}/suppliers/suppliers/${id}/territories_covered_areas_count/`;
export const getSupplierCoveredCities = (id) =>
  `${baseURL}/suppliers/suppliers/${id}/cities_covered_areas_count/`;
export const getSupplierCoveredAreas = (id) =>
  `${baseURL}/suppliers/suppliers/${id}/city_areas_is_covered/`;
export const patchSupplier = (id) => `${baseURL}/suppliers/suppliers/${id}/`;
export const getSuppliersApi = `${baseURL}/suppliers/suppliers/`;
export const getContacts = (entityId, objectId) =>
  `${baseURL}/contacts/contacts/?entity_content_type_id=${entityId}&entity_object_id=${objectId}&name_only=false`;
export const getDocuments = (entityId, objectId) =>
  `${baseURL}/documents/documents/?entity_content_type_id=${entityId}&entity_object_id=${objectId}`;
export const deleteSupplier = (id) => `${baseURL}/suppliers/suppliers/${id}/`;
export const patchSupplierSuppliesApi = (id) =>
  `${baseURL}/suppliers/suppliers/${id}/supplies/`;

// Geographics
export const getCountriesApi = `${baseURL}/geographics/countries/`;
export const getTerritoriesApi = `${baseURL}/geographics/territories/`;
export const getCitiesApi = `${baseURL}/geographics/cities/`;
export const getAreasApi = `${baseURL}/geographics/areas/`;

// Supplies
export const getSuppliesCategories = `${baseURL}/supplies/categories/`;
export const getSuppliesStorages = `${baseURL}/supplies/storages/`;
export const newSupplyApi = `${baseURL}/supplies/supplies/`;
export const getSuppliesApi = `${baseURL}/supplies/supplies/`;
export const deleteSupplyApi = (id) => `${baseURL}/supplies/supplies/${id}/`;
export const patchSupplyApi = (id) => `${baseURL}/supplies/supplies/${id}/`;
export const getSupplyApi = (id) => `${baseURL}/supplies/supplies/${id}/`;

// Equipments
export const getPowerSourcesApi = `${baseURL}/equipment/power_sources/`;
export const getEquipmentCategories = `${baseURL}/equipment/categories/`;
export const getEquipmentsApi = `${baseURL}/equipment/equipment/`;
export const newEquipmentApi = `${baseURL}/equipment/equipment/`;
export const deleteEquipmentApi = (id) =>
  `${baseURL}/equipment/equipment/${id}/`;
export const patchEquipmentApi = (id) =>
  `${baseURL}/equipment/equipment/${id}/`;
export const getEquipmentApi = (id) => `${baseURL}/equipment/equipment/${id}/`;

// Channels
export const getChannelsApi = `${baseURL}/channels/channels/`;
export const newChannelsApi = `${baseURL}/channels/channels/`;
export const getChannelApi = (id) => `${baseURL}/channels/channels/${id}/`;
export const patchChannelApi = (id) => `${baseURL}/channels/channels/${id}/`;
export const deleteChannelApi = (id) => `${baseURL}/channels/channels/${id}/`;

// Menu Component
export const getMenuComponentsApi = `${baseURL}/menus/components/`;
export const getMenuComponentApi = (id) => `${baseURL}/menus/components/${id}/`;
export const newMenuComponentApi = `${baseURL}/menus/components/`;
export const patchMenuComponentApi = (id) =>
  `${baseURL}/menus/components/${id}/`;
export const deleteMenuComponentApi = (id) =>
  `${baseURL}/menus/components/${id}/`;
export const patchMenuComponentSuppliesApi = (id) =>
  `${baseURL}/menus/components/${id}/supplies/`;
export const patchMenuComponentEquipmentsApi = (id) =>
  `${baseURL}/menus/components/${id}/equipment/`;

// Menu Items
export const getMenuItemsApi = `${baseURL}/menus/items/`;
export const getMenuItemApi = (id) => `${baseURL}/menus/items/${id}/`;
export const newMenuItemsApi = `${baseURL}/menus/items/`;
export const patchMenuItemApi = (id) => `${baseURL}/menus/items/${id}/`;
export const deleteMenuItemApi = (id) => `${baseURL}/menus/items/${id}/`;
export const patchMenuItemsComponentsApi = (id) =>
  `${baseURL}/menus/items/${id}/components/`;

// Admin Labels
export const getContactsLabelsApi = `${baseURL}/contacts/labels/`;

// Contacts
export const postNewContact = `${baseURL}/contacts/contacts/`;
export const deleteContact = (id) => `${baseURL}/contacts/contacts/${id}/`;
export const getContactDetailsApi = (id) =>
  `${baseURL}/contacts/contacts/${id}/`;
export const patchContact = (id) => `${baseURL}/contacts/contacts/${id}/`;

// Documents
export const getDocumentDetailsApi = (id) =>
  `${baseURL}/documents/documents/${id}/`;
export const deleteDocumentApi = (id) =>
  `${baseURL}/documents/documents/${id}/`;
export const getDocumentsLabelsApi = `${baseURL}/documents/labels/`;
export const newDocumentApi = `${baseURL}/documents/documents/`;
export const editDocumentApi = (id) => `${baseURL}/documents/documents/${id}/`;

// Cuisines
export const getCuisinesTypesApi = `${baseURL}/cuisines/types/`;

// Working Shifts
export const getWorkingShiftsApi = `${baseURL}/kitchens/shifts/`;

// Brands
export const getMenusBrandsApi = `${baseURL}/brands/brands/`;
export const getMenusBrand = (id) => `${baseURL}/brands/brands/${id}/`;
export const postNewBrandApi = `${baseURL}/brands/brands/`;
export const deleteBrandApi = (id) => `${baseURL}/brands/brands/${id}/`;
export const getBrandApi = (id) => `${baseURL}/brands/brands/${id}/`;
export const patchBrandApi = (id) => `${baseURL}/brands/brands/${id}/`;
export const patchBrandEquipmentsApi = (id) =>
  `${baseURL}/brands/brands/${id}/equipment/`;
export const getBrandCoveredCountries = (id) =>
  `${baseURL}/brands/brands/${id}/countries_covered_areas_count`;
export const getBrandCoveredTerritories = (id) =>
  `${baseURL}/brands/brands/${id}/territories_covered_areas_count`;
export const getBrandCoveredCities = (id) =>
  `${baseURL}/brands/brands/${id}/cities_covered_areas_count`;
export const getBrandCoveredAreas = (id) =>
  `${baseURL}/brands/brands/${id}/city_areas_is_covered`;

// Menus
export const getMenusApi = `${baseURL}/menus/menus/`;
export const newMenuBrandApi = `${baseURL}/menus/menus/`;
export const patchMenuBrandApi = (id) => `${baseURL}/menus/menus/${id}/`;
export const patchMenuItemsApi = (id) => `${baseURL}/menus/menus/${id}/items/`;
export const deleteMenuBrandApi = (id) => `${baseURL}/menus/menus/${id}/`;
export const getMenuDataApi = (id) => `${baseURL}/menus/menus/${id}/`;
export const getMenuDetailApi = (id) => `${baseURL}/menus/menus/${id}/`;

// Firms
export const getFirmsApi = `${baseURL}/firms/firms/`;
export const getFirmDataApi = (id) => `${baseURL}/firms/firms/${id}/`;
export const postNewFirmApi = `${baseURL}/firms/firms/`;
export const patchFirmDataApi = (id) => `${baseURL}/firms/firms/${id}/`;
export const deleteFirmApi = (id) => `${baseURL}/firms/firms/${id}/`;

// Groups
export const getGroupsApi = `${baseURL}/kitchens/groups/`;
export const postNewGroupApi = `${baseURL}/kitchens/groups/`;
export const getGroupDataApi = (id) => `${baseURL}/kitchens/groups/${id}/`;
export const patchGroupDataApi = (id) => `${baseURL}/kitchens/groups/${id}/`;
export const deleteGroupApi = (id) => `${baseURL}/kitchens/groups/${id}/`;

// Kitchens
export const getGroupsKitchensApi = `${baseURL}/kitchens/kitchens/`;
export const deleteGroupKitchenApi = (id) =>
  `${baseURL}/kitchens/kitchens/${id}/`;
export const patchKitchenEquipmentsApi = (id) =>
  `${baseURL}/kitchens/kitchens/${id}/equipment/`;
export const patchKitchenBrandsApi = (id) =>
  `${baseURL}/kitchens/kitchens/${id}/brands/`;
export const patchKitchenChannelsApi = (id) =>
  `${baseURL}/kitchens/kitchens/${id}/channels/`;
export const postNewKitchenApi = `${baseURL}/kitchens/kitchens/`;
export const patchKitchenDetailsApi = (id) =>
  `${baseURL}/kitchens/kitchens/${id}/`;
export const getKitchenShiftsApi = `${baseURL}/kitchens/shifts/`;

// Training Materials
export const getTrainingMaterialsApi = `${baseURL}/training-materials/materials/`;

// Matchmaking
export const getMatchApi = `${baseURL}/matchmakings/searches/match/`;
export const postMatchmakingApi = `${baseURL}/matchmakings/requests/`;
export const getRequestsApi = `${baseURL}/matchmakings/requests/`;
export const getSamplingsRequestsApi = `${baseURL}/samplings/requests/`;

// Sampling
export const getSamplingMatchApi = `${baseURL}/samplings/searches/match/`;
export const postSamplingRequestApi = `${baseURL}/samplings/requests/`;
