export function extractDayMonthTime(propTime, propDate, language) {
  const dateTime = new Date(propTime);
  // Both orderTime and orderDate are always equal here I just needed to call them both to make the state re render for both values if any were edited by the user.
  const date = new Date(propDate);
  const month = dateTime.toLocaleString(`${language}`, { month: 'short' });
  const day = date.toLocaleString(`${language}`, { day: '2-digit' });
  const time = dateTime.toLocaleTimeString(`${language}`, {
    hour: '2-digit',
    minute: '2-digit',
  });
  return { day, month, time };
}

export const extractFirstLetters = (name) => {
  const firstLetter = name?.slice(0, 1);
  const secondLetter = name?.slice(
    name.indexOf(' ') + 1,
    name.indexOf(' ') + 2
  );
  return [firstLetter, secondLetter];
};

// This function is used to handle changing the time and date in the "new" & "edit" incident form to "ISO".
export const handleTime = (orderDate) => {
  if (!orderDate) {
    return null;
  }
  const date = new Date(orderDate);
  // getTimezoneOffset returns the offset in (-) minutes.
  const timeZoneOffset = date.getTimezoneOffset();
  let concatDateTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  // Axios intercept all requests and convert the date time to UTC by default. the below will increase the time by the timezone offset which will allow us to send the time as the local zone.
  const dateTime = new Date(
    concatDateTime.getTime() - timeZoneOffset * 60 * 1000
  );
  return dateTime;
};

// This function take date, and language, return the date('according to the english format') and time('according to user language').
export const extractDateTime = (originalDateTime, language) => {
  const dateTime = new Date(originalDateTime);
  const date = dateTime.toLocaleDateString('en-GB');
  const time = dateTime.toLocaleTimeString(`${language}`, {
    hour: '2-digit',
    minute: '2-digit',
  });
  return [date, time];
};

export const checkLocation = (success, fail) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, fail);
  } else {
    return 'Geolocation is not supported by this browser.';
  }
};

export const handleCardSelection = (
  cardId,
  originalSelectedCardIds,
  newlySelectedCardIds,
  newlyDeselectedCardIds
) => {
  // Create copies of the input arrays to avoid modifying the originals.
  let updatedOriginalSelectedCardIds = [...originalSelectedCardIds];
  let updatedNewlySelectedCardIds = [...newlySelectedCardIds];
  let updatedNewlyDeselectedCardIds = [...newlyDeselectedCardIds];

  // Check if the card is originally selected.
  if (originalSelectedCardIds.includes(cardId)) {
    // If it is originally selected, remove it from the original selected list.
    updatedOriginalSelectedCardIds = originalSelectedCardIds.filter(
      (id) => id !== cardId
    );

    // Check if the card is also newly selected.
    if (newlySelectedCardIds.includes(cardId)) {
      // If it's newly selected, remove it from the newly selected list.
      updatedNewlySelectedCardIds = newlySelectedCardIds.filter(
        (id) => id !== cardId
      );
    } else {
      // If it's not newly selected, add it to the newly deselected list.
      updatedNewlyDeselectedCardIds = [...newlyDeselectedCardIds, cardId];
    }
  } else {
    // If the card is not originally selected:
    // Remove it from the newly deselected list (if present).
    updatedNewlyDeselectedCardIds = newlyDeselectedCardIds.filter(
      (id) => id !== cardId
    );

    // Add it to the original selected list.
    updatedOriginalSelectedCardIds = [...originalSelectedCardIds, cardId];

    // Check if the card is not in the original selected or newly deselected lists.
    if (
      !originalSelectedCardIds.includes(cardId) &&
      !newlyDeselectedCardIds.includes(cardId)
    ) {
      // If it's not in those lists, add it to the newly selected list.
      updatedNewlySelectedCardIds = [...newlySelectedCardIds, cardId];
    }
  }

  // Return the three updated arrays.
  return [
    updatedOriginalSelectedCardIds,
    updatedNewlySelectedCardIds,
    updatedNewlyDeselectedCardIds,
  ];
};

export function extractDayMonthYear(date) {
  const convertedDate = new Date(date);
  const day = convertedDate.toLocaleString('en-US', { day: '2-digit' });
  const month = convertedDate.toLocaleString('en-US', { month: 'short' });
  const year = convertedDate.toLocaleString('en-US', { year: 'numeric' });

  return { day, month, year };
}

// This function is responsible for generating a random number between two variables
export function generateNumber(minimumValue, maximumValue) {
  return Math.floor(
    Math.random() * (maximumValue - minimumValue) + minimumValue
  );
}
