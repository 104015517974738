import '../styles/globals.css';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { CustomTheme } from '../components/general/theme';
import { store } from '../services/redux-toolkit/store';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { appWithTranslation } from 'next-i18next';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';
import { Header } from '../components/general/header/header';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Footer } from '../components/general/footer';
import { userSlice } from '../services/redux-toolkit/slices/userSlice';
import {
  kitchenSlice,
  permissions,
  isKitchIn,
} from '../services/redux-toolkit/slices/kitchenSlice';
import { en, ar } from 'date-fns/locale';
import { Box, Fab } from '@mui/material';
import useFetch from '../services/utilities/useFetch';
import {
  getKitchensApi,
  getUserApi,
  getSupport,
} from '../services/utilities/apiConfig';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { ComponentWrapper } from '../components/general/componentWrapper';
import { AdminLayout } from '../components/admin/adminLayout';
import Loading from '../components/general/loading';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Link from 'next/link';

function MyApp({ Component, pageProps }) {
  const [loading, setLoading] = useState(true);
  const Router = useRouter();
  const [getFetch] = useFetch();
  const [support, setSupport] = useState({});

  async function getInitialData() {
    setLoading(true);
    const token = Cookies.get('refreshToken');
    if (token) {
      const res = await Promise.all([
        getFetch(getUserApi),
        getFetch(getKitchensApi),
        getFetch(getSupport),
      ]);
      const user = res[0]?.data;
      const kitchens = res[1]?.data;
      setSupport(res[2]?.data);

      const currentKitchen =
        localStorage.getItem('currentKitchen') || kitchens[0]?.kitchen?.id;
      store.dispatch(userSlice?.actions?.saveUser(user));
      store.dispatch(kitchenSlice?.actions?.saveKitchen(kitchens));
      store.dispatch(kitchenSlice?.actions?.selectedKitchen(currentKitchen));
      store.dispatch(permissions());
      store.dispatch(isKitchIn());

      if (Router.locale != user?.profile?.language) {
        Router.push(Router.asPath, undefined, {
          locale: user?.profile?.language,
        });
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    try {
      getInitialData();
    } catch (e) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (Router.locale == 'en') {
      document.body.dir = 'ltr';
    } else {
      document.body.dir = 'rtl';
    }
  }, [Router.locale]);

  const cacheRtl = createCache({
    key: 'muirtl',
    prepend: true, ///true : make the css file override the mui style
    stylisPlugins: [prefixer, rtlPlugin],
  });
  const cacheLtr = createCache({
    key: 'muiltr',
    prepend: true,
  });

  const theme = CustomTheme(Router.locale);

  /*
  QM => Quality Management Platform
  DH => DataHub Platform
*/
  const platform = Router.asPath.startsWith('/quality-management')
    ? 'QM'
    : 'DH';

  /////

  function LayoutStructure() {
    if (platform == 'DH') {
      return (
        <AdminLayout>
          <ComponentWrapper>
            <Component {...pageProps} />
          </ComponentWrapper>
        </AdminLayout>
      );
    } else if (platform == 'QM') {
      return (
        <Box className='Father'>
          <Box>
            <Header />
            <ComponentWrapper>
              <Component {...pageProps} />
            </ComponentWrapper>
          </Box>
          <Footer />
        </Box>
      );
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Provider store={store}>
      <CacheProvider value={Router.locale === 'ar' ? cacheRtl : cacheLtr}>
        <CssVarsProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={Router.locale === 'en' ? en : ar}
          >
            <WhatsAppSupport data={support} />
            {Component.getLayout ? (
              Component.getLayout(
                <ComponentWrapper>
                  <Component {...pageProps} />
                </ComponentWrapper>
              )
            ) : (
              <LayoutStructure />
            )}
          </LocalizationProvider>
        </CssVarsProvider>
      </CacheProvider>
    </Provider>
  );
}

function WhatsAppSupport({ data }) {
  return (
    <Box sx={{ position: 'fixed', bottom: 40, right: 30, zIndex: 3 }}>
      <Link
        href={`https://wa.me/${data?.support_whatsapp_number}`}
        target='_blank'
      >
        <Fab
          sx={{
            color: '#fff',
            backgroundColor: '#25d366',
            '&:hover': {
              backgroundColor: '#25d366',
            },
          }}
        >
          <WhatsAppIcon sx={{ fontSize: '2rem' }} />
        </Fab>
      </Link>
    </Box>
  );
}

export default appWithTranslation(MyApp);
