import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const initialState = {
  info: {
    first_name: '',
    last_name: '',
    email: '',
    profile: {
      image: '',
      language: 'en',
    },
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser(state, action) {
      state.info = action.payload; // its enough because of immer

      const profileData = action?.payload?.profile;
      let type = '';
      if (profileData?.is_kitchefy_admin) {
        // DataHub Kitchefy Admin
        type = 'DHKA';
      } else if (
        profileData?.is_brand_owner &&
        profileData?.is_fulfillment_partner
      ) {
        // DataHub Full Partner
        type = 'DHFP';
      } else if (profileData?.is_fulfillment_partner) {
        // DataHub Fulfillment Partner
        type = 'DHFF';
      } else if (profileData?.is_brand_owner) {
        // DataHub Brand Partner
        type = 'DHBP';
      } else if (profileData?.is_sampler) {
        // DataHub Sampler
        type = 'DHSA';
      }

      if (type == '') {
        Cookies.remove('type', {
          path: '/',
          secure: true,
          expires: 365,
        });
      } else {
        Cookies.set('type', type, {
          path: '/',
          secure: true,
          expires: 365,
        });
      }

      if (profileData?.has_profile_kitchens) {
        Cookies.set('QM', 'QM', { path: '/', secure: true, expires: 365 });
      } else {
        Cookies.remove('QM', { path: '/', secure: true, expires: 365 });
      }
    },

    updateLanguage(state, action) {
      state.info.profile.language = action.payload;
    },
  },
});
