import { useSelector } from 'react-redux';

export default function usePermission() {
  const profileDetails = useSelector(
    (state) => state?.userSlice?.info?.profile
  );

  function getCurrentPermission() {
    if (profileDetails?.is_kitchefy_admin) {
      return 'Kitchefy';
    } else if (
      profileDetails?.is_fulfillment_partner &&
      profileDetails?.is_brand_owner
    ) {
      return 'Full Partner';
    } else if (profileDetails?.is_fulfillment_partner) {
      return 'Fulfillment Partner';
    } else if (profileDetails?.is_brand_owner) {
      return 'Brand Partner';
    } else if (profileDetails?.is_sampler) {
      return 'Sampling';
    }
  }

  const currentPermission = getCurrentPermission();

  function hasPermission(permission) {
    if (Array.isArray(permission) && permission.length > 0) {
      return permission?.includes(currentPermission);
    } else {
      return currentPermission == permission;
    }
  }

  return [hasPermission];
}
