import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

export default function Score({ score, color }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '50px',
      }}
    >
      <StarIcon
        sx={{
          color: '#d4af37',
          height: '19px',
        }}
      />
      <Typography sx={{ color: color, fontSize: '14px' }}>{score}</Typography>
    </Box>
  );
}
