import { Box } from '@mui/material';
import { SideBar } from './sideBar';
import { AdminHeader } from './adminHeader';
import { AdminFooter } from './adminFooter';

export const AdminLayout = ({ children }) => {
  return (
    <>
      <AdminHeader />
      <Box sx={{ display: 'flex', position: 'relative' }}>
        <SideBar />
        <Box
          className='adminContainer'
          sx={{
            width: '100%',
            backgroundColor: 'admin.background',
            mt: '71px',
          }}
        >
          <Box
            sx={{
              maxWidth: '1076px',
              margin: '0 auto',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
      <AdminFooter />
    </>
  );
};
