import { useRouter } from 'next/router';
import { UpdateLanguage } from './apiConfig';
import useFetch from './useFetch';
import { useSelector, useDispatch } from 'react-redux';
import { userSlice } from '../redux-toolkit/slices/userSlice';

function useChangeLanguage() {
  const [getFetch, postFetch, patchFetch] = useFetch();
  const oldUser = useSelector((state) => state.userSlice.info);
  const dispatch = useDispatch();
  const Router = useRouter();
  async function changeLanguage(e, value) {
    e.preventDefault();
    if (oldUser != null) {
      let res = await patchFetch(
        UpdateLanguage,
        { language: value },
        { language: Router.locale }
      );
      if (res.status == 200) {
        dispatch(userSlice.actions.updateLanguage(value));
        Router.push(Router.asPath, undefined, { locale: value, shallow: true });
        Router.events.on('routeChangeComplete', () => {
          Router.reload(Router.asPath);
        });
      }
    }
  }
  return [changeLanguage];
}
export default useChangeLanguage;
