import { Menu, MenuItem, Typography, Tooltip, Box } from '@mui/material';
import style from '../../../styles/headFooter.module.css'; //HEADER_STYLE
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

export const HeaderGeneralMenu = ({
  data = [],
  handleFunction = () => {},
  anchorEl,
  setAnchorEl,
  position = undefined,
}) => {
  const { t } = useTranslation('incidents', 'common');
  const close = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={close}
      elevation={2}
      disableScrollLock={true}
      sx={{ padding: '0px' }}
      MenuListProps={{
        sx: {
          backgroundColor: 'brand.secondaryDark',
          padding: '0px',
          boxShadow: 'none',
          minWidth: '180px',
        },
      }}
      {...position}
    >
      {data.map((item) => (
        <Tooltip
          title={item?.allowed == false ? t('common:no_permission') : ''}
          arrow
          key={item.name}
        >
          <Box>
            <MenuItem
              onClick={close}
              disabled={item.allowed == false}
              sx={{ p: 0 }}
            >
              <Link
                href={
                  item.value != 'en' && item.value != 'ar'
                    ? `${item.value}`
                    : ''
                }
                prefetch={false}
                onClick={(e) => {
                  handleFunction(e, item.value);
                  close();
                }}
                style={{ width: '100%', height: '100%' }}
              >
                <Typography
                  className={style.headFont}
                  sx={{ color: 'grey.white', p: '14px 10px' }}
                >
                  {item.name}
                </Typography>
              </Link>
            </MenuItem>
          </Box>
        </Tooltip>
      ))}
    </Menu>
  );
};
