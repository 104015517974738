import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from './slices/userSlice';
import { kitchenSlice } from './slices/kitchenSlice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from 'redux';
import { alertsSlice } from './slices/alertsSlice';
export const store = configureStore({
  reducer: combineReducers({
    userSlice: userSlice.reducer,
    kitchenSlice: kitchenSlice.reducer,
    alertsSlice: alertsSlice.reducer,
  }),
});
setupListeners(store.dispatch);
