import { Box, Divider, Typography, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import style from '../../../../../styles/headFooter.module.css';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { CustomModal } from '../../../customModal';
import { useRouter } from 'next/router';
import { KitchInProgress } from '../../../../kitchIO/kitchInOutProgress/kitchInProgress';

export const UpperSection = ({ toggleDrawer }) => {
  const [incidents, setIncidents] = useState('');
  const [openKitchPopup, setOpenKitchPopup] = useState(false);
  const [checkKitchIn, setCheckKitchIn] = useState('');
  const { t } = useTranslation('incidents', 'common', 'checklists');
  const router = useRouter();
  const permissions = useSelector((state) => state.kitchenSlice.permissions);
  const isKitchIn = useSelector((state) => state.kitchenSlice.isKitchIn);
  const handleChange = (e, setState) => {
    setState(e.target.value);
  };
  useEffect(() => {
    setCheckKitchIn(isKitchIn);
  }, [isKitchIn]);
  return (
    <Box sx={{ pt: 5, display: 'flex', flexDirection: 'column', gap: '35px' }}>
      <Button
        variant='outlined'
        size='small'
        sx={{
          color: '#fff',
          borderColor: '#fff',
          p: '3px 23px',
          '&:hover': { borderColor: '#fff' },
        }}
        disabled={
          !permissions.can_kitch_in_out ||
          (router.asPath.startsWith('/quality-management/kitchios/') &&
            router.asPath.endsWith('/submit'))
        }
        onClick={() => {
          setOpenKitchPopup(true);
          toggleDrawer();
        }}
      >
        {checkKitchIn == 'in' ? t('common:kitch_out') : t('common:kitch_in')}
      </Button>
      <CustomModal open={openKitchPopup} setOpen={setOpenKitchPopup}>
        <KitchInProgress setAnchor={setOpenKitchPopup} />
      </CustomModal>
      <Box onClick={() => toggleDrawer()}>
        <Link
          href='/quality-management'
          sx={{ width: '100%', height: '100%' }}
          prefetch={false}
        >
          <Typography
            className={style.headerMobileFontSelect}
            sx={{ color: 'grey.white', width: '100%' }}
          >
            {t('common:my_kitchen')}
          </Typography>
        </Link>
        <Divider sx={{ borderColor: 'grey.white' }} />
      </Box>
      <Box onClick={() => toggleDrawer()}>
        <Link
          href='/quality-management/incidents'
          sx={{ width: '100%', height: '100%' }}
          prefetch={false}
        >
          <Typography
            className={style.headerMobileFontSelect}
            sx={{ color: 'grey.white', width: '100%' }}
          >
            {t('common:incidents')}
          </Typography>
        </Link>
        <Divider sx={{ borderColor: 'grey.white' }} />
      </Box>
      <Box onClick={() => toggleDrawer()}>
        <Link
          href='/quality-management/checklists'
          sx={{ width: '100%', height: '100%' }}
          prefetch={false}
        >
          <Typography
            className={style.headerMobileFontSelect}
            sx={{ color: 'grey.white', width: '100%' }}
          >
            {t('common:checklists')}
          </Typography>
        </Link>
        <Divider sx={{ borderColor: 'grey.white' }} />
      </Box>
      <Box onClick={() => toggleDrawer()}>
        <Link
          href='/quality-management/kitchios'
          sx={{ width: '100%', height: '100%' }}
          prefetch={false}
        >
          <Typography
            className={style.headerMobileFontSelect}
            sx={{ color: 'grey.white', width: '100%' }}
          >
            {t('common:kitch_in_out')}
          </Typography>
        </Link>
        <Divider sx={{ borderColor: 'grey.white' }} />
      </Box>
    </Box>
  );
};
