import { Box, Typography, Avatar } from '@mui/material';
import { useState } from 'react';
import style from '../../../../../styles/headFooter.module.css';
import { useRouter } from 'next/router';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useSelector } from 'react-redux';
import { HeaderSettingsMenu } from '../../headerSettingsMenu';
import { HeaderGeneralMenu } from '../../headerGeneralMenu';
import useChangeLanguage from '../../../../../services/utilities/useChangeLanguage';

export const LowerSection = ({ languages, Kitchens }) => {
  const [anchorElSettings, setAnchorElSettings] = useState();
  const [anchorElLang, setAnchorElLang] = useState();
  const Router = useRouter();
  const user = useSelector((state) => state.userSlice.info);

  const handleButtonClick = (event, setAnchorEl) => {
    setAnchorEl(event?.currentTarget);
  };
  const [changeLanguage] = useChangeLanguage();
  return (
    <Box
      sx={{
        color: 'grey.white',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box
        sx={{ display: 'flex' }}
        className={style.headList}
        onClick={(e) => handleButtonClick(e, setAnchorElLang)}
      >
        <Typography className={style.headFont}>{Router.locale}</Typography>
        <ArrowDropUpIcon fontSize='small' />
      </Box>
      <HeaderGeneralMenu
        data={languages}
        anchorEl={anchorElLang}
        setAnchorEl={setAnchorElLang}
        handleFunction={changeLanguage}
        position={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
      />
      <Box
        onClick={(e) => handleButtonClick(e, setAnchorElSettings)}
        sx={{ display: 'flex' }}
        className={style.headList}
      >
        <Avatar
          sx={{
            width: 41,
            height: 41,
            backgroundColor: 'inherit',
          }}
          src={user?.profile?.image?.length > 0 ? user?.profile?.image : null}
        >
          <Typography
            className={style.nameStyle}
            sx={{
              color: 'brand.secondaryDark',
              backgroundColor: 'grey.white',
            }}
          >
            {user?.first_name.slice(0, 1)}
            {user?.last_name.slice(0, 1)}
          </Typography>
        </Avatar>
        <ArrowDropUpIcon fontSize='small' />
      </Box>
      <HeaderSettingsMenu
        list={Kitchens}
        anchorEl={anchorElSettings}
        setAnchorEl={setAnchorElSettings}
        position={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
      />
    </Box>
  );
};
