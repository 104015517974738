import { Box, Container, Typography } from '@mui/material';
import style from '../../styles/headFooter.module.css';
import { useTranslation } from 'next-i18next';
import useFetch from '../../services/utilities/useFetch';
import { useEffect, useState } from 'react';
import { getServerDate } from '../../services/utilities/apiConfig';
export const AdminFooter = () => {
  const { t } = useTranslation('common');
  const [getFetch] = useFetch();
  const [year, setYear] = useState('');
  useEffect(() => {
    const serverDateApi = () => {
      getFetch(getServerDate).then((response) => {
        setYear(new Date(response.data.detail).getFullYear());
      });
    };
    serverDateApi();
  }, []);
  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: 'admin.footerColor',
        width: '100%',
        zIndex: '2',
      }}
    >
      <Container maxWidth='lg'>
        <Box className={style.footerBox}>
          <Box className={style.footerInnerBox}></Box>
          <Box>
            <Typography
              className={style.footSmText}
              sx={{ color: 'grey.dark' }}
            >
              &copy;{`Kitchefy ${year}`}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
