import { Box, Button, Tooltip } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  kitchenSlice,
  isKitchIn,
} from '../../../services/redux-toolkit/slices/kitchenSlice';
import { getKitchensApi } from '../../../services/utilities/apiConfig';
import useFetch from '../../../services/utilities/useFetch';
import { KitchInProgress } from '../../kitchIO/kitchInOutProgress/kitchInProgress';
import { CustomModal } from '../customModal';
///
export const HeaderKitchInOut = () => {
  const { t } = useTranslation('common');
  const [openKitchPopup, setOpenKitchPopup] = useState(false);
  // const [logIn, setLogIN] = useState();
  const isKitchInStatus = useSelector((state) => state.kitchenSlice.isKitchIn);
  const permissions = useSelector((state) => state.kitchenSlice.permissions);
  const favKitchen = useSelector((state) => state.kitchenSlice.currentKitchen);
  const [getFetch] = useFetch();
  const dispatch = useDispatch();

  const router = useRouter();
  const isInitialMount = useRef(true);
  useEffect(() => {
    const updateKitchenApi = async () => {
      const response = await getFetch(getKitchensApi).then((response) => {
        dispatch(kitchenSlice?.actions?.saveKitchen(response?.data));
      });
    };
    ///to not call the api twice until update the fav kitchen
    if (favKitchen)
      if (isInitialMount.current) isInitialMount.current = false;
      else {
        updateKitchenApi();
        //   setLogIN(isKitchInStatus);
      }
  }, [favKitchen]);

  // useEffect(() => {
  //   setLogIN(isKitchInStatus);
  // }, [isKitchInStatus]);

  return (
    <Box>
      <Tooltip
        title={permissions.can_kitch_in_out ? '' : t('common:no_permission')}
        arrow
      >
        <Box>
          <Button
            variant='outlined'
            size='small'
            sx={{
              color: '#fff',
              borderColor: '#fff',
              p: '3px 23px',
              '&:hover': { borderColor: '#fff' },
            }}
            onClick={() => setOpenKitchPopup(true)}
            disabled={
              !permissions.can_kitch_in_out ||
              (router.asPath.startsWith('/quality-management/kitchios/') &&
                router.asPath.endsWith('/submit'))
            }
          >
            {isKitchInStatus == 'in'
              ? t('common:kitch_out')
              : t('common:kitch_in')}
          </Button>
        </Box>
      </Tooltip>

      <CustomModal open={openKitchPopup} setOpen={setOpenKitchPopup}>
        <KitchInProgress
          setAnchor={setOpenKitchPopup}
          // setLogIN={setLogIN}
          //type={logIn}
        />
      </CustomModal>
    </Box>
  );
};
