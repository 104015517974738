import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import style from '../../styles/headFooter.module.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import useFetch from '../../services/utilities/useFetch';
import { useEffect, useState } from 'react';
import { getServerDate, getSupport } from '../../services/utilities/apiConfig';
export const Footer = () => {
  const { t } = useTranslation('common');
  const [getFetch] = useFetch();
  const [support, setSupport] = useState({});
  const [year, setYear] = useState('');
  useEffect(() => {
    const supportApi = () => {
      getFetch(getSupport).then((response) => {
        setSupport(response.data);
      });
    };
    const serverDateApi = () => {
      getFetch(getServerDate).then((response) => {
        setYear(new Date(response.data.detail).getFullYear());
      });
    };
    const getData = async () => {
      let results = await Promise.all([supportApi(), serverDateApi()]);
    };
    getData();
  }, []);
  return (
    <Box sx={{ backgroundColor: 'grey.light', mt: '50px' }}>
      <Container maxWidth='lg'>
        <Box className={style.footerBox}>
          <Box className={style.footerInnerBox}>
            <Typography
              className={style.footerText}
              sx={{
                color: 'grey.black',
                display: { xs: 'none', md: 'block' },
              }}
            >
              {t('footer.contact_support')}
            </Typography>
            <Link
              href={`https://wa.me/${support?.whatsapp_number}`}
              target='_blank'
            >
              <Box className={style.footerIcon}>
                <WhatsAppIcon sx={{ color: 'brand.secondaryDark' }} />
                <Typography
                  className={style.footSmText}
                  sx={{ color: 'grey.black' }}
                >
                  {t('footer.whats_app')}
                </Typography>
              </Box>
            </Link>
            <Link href={`mailto:${support?.email}`}>
              <Box className={style.footerIcon}>
                <MailOutlineIcon sx={{ color: 'brand.secondaryDark' }} />
                <Typography
                  className={style.footSmText}
                  sx={{ color: 'grey.black' }}
                >
                  {t('email')}
                </Typography>
              </Box>
            </Link>
          </Box>
          <Box>
            <Typography
              className={style.footSmText}
              sx={{ color: 'grey.dark' }}
            >
              &copy;{`Kitchefy ${year}`}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
