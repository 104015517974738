import { useState } from 'react';
import { Avatar, Box, Button, Typography } from '@mui/material';
import Link from 'next/link';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import style from '../../styles/headFooter.module.css';
import { HeaderSettingsMenu } from '../general/header/headerSettingsMenu';
import usePermission from '../../services/utilities/usePermissions';

export const AdminHeader = () => {
  const [anchorElSettings, setAnchorElSettings] = useState();
  const user = useSelector((state) => state.userSlice.info);

  const [hasPermission] = usePermission();

  const isSampler = hasPermission('Sampling');

  const handleButtonClick = (event, setAnchorEl) => {
    setAnchorEl(event?.currentTarget);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'admin.headerColor',
        position: 'fixed',
        top: '0px',
        left: '0px',
        right: '0px',
        zIndex: '2',
      }}
    >
      <Box sx={{ height: '71px', margin: '0rem 8.2rem 0rem 5.1rem' }}>
        <Box className={style.headFlex}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'space-between', md: 'flex-start' },
              height: '100%',
              alignItems: 'center',
            }}
          >
            <Link href='/'>
              <Image
                alt=''
                src='/images/headTitle.png'
                width={134}
                height={31}
                priority
              />
            </Link>
          </Box>
          <Box
            className={`${style.headList} ${style.headBox}`}
            sx={{
              color: 'grey.white',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {/* Matchmaking Button */}
            <Box sx={{ marginRight: '1rem' }}>
              <Link href={isSampler ? '/matchmaking/sampling' : '/matchmaking'}>
                <Button
                  sx={{
                    color: '#fff',
                    border: '2px solid',
                    borderRadius: '.35rem',
                    padding: '.5rem 1.38rem',
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: '0.9375rem',
                      fontWeight: '500',
                      lineHeight: '1.625rem',
                      letterSpacing: '0.02875rem',
                    }}
                  >
                    {isSampler ? 'Distribute' : 'Matchmaking'}
                  </Typography>
                </Button>
              </Link>
            </Box>
            <Box
              onClick={(e) => handleButtonClick(e, setAnchorElSettings)}
              sx={{
                cursor: 'pointer',
                display: { xs: 'none', md: 'flex' },
              }}
              className={style.headList}
            >
              <Avatar
                sx={{
                  width: 41,
                  height: 41,
                  backgroundColor: 'inherit',
                }}
                src={
                  user?.profile?.image?.length > 0 ? user?.profile?.image : null
                }
              >
                <Typography
                  className={style.nameStyle}
                  sx={{
                    color: 'brand.secondaryDark',
                    backgroundColor: 'grey.white',
                  }}
                >
                  {user?.first_name.slice(0, 1)}
                  {user?.last_name.slice(0, 1)}
                </Typography>
              </Avatar>
            </Box>
            <HeaderSettingsMenu
              list={[]}
              anchorEl={anchorElSettings}
              setAnchorEl={setAnchorElSettings}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
