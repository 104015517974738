import {
  Alert,
  Box,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Avatar,
  Button,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import useFetch from '../../../services/utilities/useFetch';
import style from '../../../styles/checklists/checklistResponse.module.css';
import { useState, useEffect } from 'react';
import {
  postKitchInOutRange,
  postKitchInOut,
  getSubSectionsApi,
} from '../../../services/utilities/apiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { checkLocation } from '../../../services/utilities/utils';
import { useRouter } from 'next/router';
import Loading from '../../general/loading';
import {
  isKitchIn,
  kitchenSlice,
} from '../../../services/redux-toolkit/slices/kitchenSlice';
import Cookies from 'js-cookie';
import { AvatarModal } from '../../general/avatar';

export const KitchInProgress = ({ setAnchor }) => {
  const { t } = useTranslation('common');
  const [selected, setSelected] = useState([]);
  const [subSections, setSubSections] = useState([]);
  const [coords, setCoords] = useState([]);
  const [outOfRange, setOutOfRange] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const favKitchen = useSelector((state) => state.kitchenSlice.currentKitchen);
  const [getFetch, postFetch] = useFetch();
  const Router = useRouter();
  const dispatch = useDispatch();
  const type = useSelector((state) => state.kitchenSlice.isKitchIn);
  const handleKitchIn = async () => {
    setSubmitLoading(true);
    let body = {
      kitchen: favKitchen,
      kitchen_subsections: selected,
      type: type == 'in' ? 'out' : 'in',
      location: {
        type: 'Point',
        coordinates: coords,
      },
    };
    if (coords.length == 0) delete body.location;
    if (selected.length == 0) delete body.kitchen_subsections;
    const res = await postFetch(postKitchInOut, body).then((res) => {
      setAnchor(false);
      dispatch(kitchenSlice.actions.saveKitchInUIID(res?.data?.checklist_uuid));
      dispatch(isKitchIn(type == 'in' ? 'out' : 'in'));
      // We used the cookies below to keep track of the user session, and prevent from copy pasting while allowing the same user to continue his input in case of disconnect as long as he stays in the same session.
      // We also use this cookie whenever we redirect the user the to start submission page to get the checklist structure.
      // The value of the cookie changes with each KitchIO, and the cookie will be deleted after each session.
      Cookies.set('kitchInUIID', res?.data?.checklist_uuid, {
        path: '/',
        secure: true,
      });
      if (res?.data?.checklist_uuid)
        Router.push(`/quality-management/kitchios/${res?.data?.uuid}/submit`);
      else Router.push('/quality-management/kitchios');
    });
    setSubmitLoading(false);
  };
  useEffect(() => {
    const getSubSections = async () => {
      if (type != 'in') {
        const res = await getFetch(getSubSectionsApi(favKitchen));
        setSubSections(res.data);
      }
    };
    const checkRange = async (position) => {
      const res = await postFetch(postKitchInOutRange, {
        location: {
          type: 'Point',
          coordinates: [
            position?.coords?.latitude,
            position?.coords?.longitude,
          ],
        },
        kitchen_id: favKitchen,
      }).then((res) => {
        setOutOfRange(res.data.out_of_range);
      });
    };
    checkLocation(
      async (position) => {
        setCoords([
          ...coords,
          position?.coords?.latitude,
          position?.coords?.longitude,
        ]);
        await Promise.all([getSubSections(), checkRange(position)]);
        setLoading(false);
      },
      async (fail) => {
        setOutOfRange(true);
        await getSubSections();
        setLoading(false);
      }
    );
  }, []);

  const handleChange = (e, checked, subsection) => {
    if (checked) setSelected([...selected, subsection.id]);
    else {
      let index = selected.findIndex((item) => item == subsection.id);
      selected.splice(index, 1);
      setSelected([...selected]);
    }
  };
  if (loading)
    return (
      <Box
        sx={{
          width: '300px',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress sx={{ color: 'brand.secondaryDark' }} />
      </Box>
    );
  return (
    <Box sx={{ p: { xs: '15px', md: '40px' } }}>
      {submitLoading && <Loading />}
      <Typography
        component='h6'
        className={style.responseBoldText}
        sx={{ fontSize: '24px', mb: 3 }}
      >
        {type == 'in' ? t('kitch_out') : t('kitch_in')}
      </Typography>
      <Box>
        {outOfRange && (
          <Alert
            severity='warning'
            sx={{ my: 3, fontSize: { xs: '14px', md: '16px' } }}
          >
            {t(`kitch_${type}_out_range`)}
          </Alert>
        )}
        {type != 'out' && (
          <Typography sx={{ fontWeight: 900 }}>
            {t('are_you_sure_you_want_to_kitchout')}
          </Typography>
        )}
        {type != 'in' && subSections?.length > 0 && (
          <Typography
            component='p'
            sx={{
              mb: 2,
              fontSize: { xs: '14px', md: '16px' },
              color: 'grey.dark',
            }}
            className={style.responseBoldText}
          >
            {t('select_subsection')}
          </Typography>
        )}
      </Box>
      {type != 'in' && subSections?.length > 0 && (
        <Box sx={{ maxHeight: '200px', overflow: 'auto', my: '40px' }}>
          {subSections?.map((subsection) => (
            <FormGroup key={subsection.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selected?.findIndex((item) => item == subsection.id) > -1
                    }
                    onChange={(e, checked) =>
                      handleChange(e, checked, subsection)
                    }
                    name='subSections'
                    sx={{ ml: 1 }}
                  />
                }
                label={
                  <Box sx={{ display: 'flex', gap: '5px' }}>
                    <AvatarModal
                      image={subsection.image}
                      name={subsection.name}
                      height='1.56rem'
                      width='1.56rem'
                    />
                    <Typography
                      className={style.responseNormalText}
                      sx={{
                        fontSize: { xs: '16px', md: '18px' },
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {subsection.name}
                    </Typography>
                  </Box>
                }
              />
            </FormGroup>
          ))}
        </Box>
      )}
      <Box
        className={style.flexRow}
        sx={{ mt: type == 'in' || subSections?.length == 0 ? '40px' : '0px' }}
      >
        <Button
          variant='cancel'
          sx={{ color: 'grey.black' }}
          onClick={() => setAnchor(false)}
        >
          {t('cancel')}
        </Button>
        <Button variant='main' onClick={() => handleKitchIn()}>
          {type != 'in' ? t('kitch_in') : t('kitch_out')}
        </Button>
      </Box>
    </Box>
  );
};
