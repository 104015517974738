import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
const initialState = {
  info: [],
  currentKitchen: '',
  isKitchIn: '',
  kitchInUIID: null,
  permissions: {},
};
////
export const permissions = createAsyncThunk(
  'kitchen/permissions',
  (action, state) => {
    const kitchens = state.getState().kitchenSlice.info;
    const current = state.getState().kitchenSlice.currentKitchen;
    const per = kitchens.filter((item) => item.kitchen.id == current);
    const respone = {
      can_add_incident: per[0].can_add_incident,
      can_receive_notifications: per[0].can_receive_incidents_notifications,
      can_resolve_incident: per[0].can_resolve_incident,
      is_kitchen_manager: per[0].is_kitchen_manager,
      can_kitch_in_out: per[0].kitchio_settings.enabled
        ? per[0]?.can_kitch_in_out
        : false,
      can_submit_checklists: per[0].can_submit_checklists,
      can_review_checklists: per[0].can_review_checklists,
      can_review_kitch_in_out: per[0].can_review_kitch_in_out,
    };
    return respone;
  }
);
export const isKitchIn = createAsyncThunk(
  'kitchen/isKitchIn',
  (action, state) => {
    if (action) return action;
    else {
      const current = state.getState().kitchenSlice.currentKitchen;
      const kitchens = state.getState().kitchenSlice.info;
      const check = kitchens.find((item) => item.kitchen.id == current);
      return check.user_last_kitchio?.type || 'out';
    }
  }
);

///
export const kitchenSlice = createSlice({
  name: 'kitchen',
  initialState,
  reducers: {
    saveKitchen(state, action) {
      state.info = action.payload;
    },
    selectedKitchen(state, action) {
      state.currentKitchen = action.payload;
    },
    saveKitchInUIID(state, action) {
      state.kitchInUIID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(permissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
    });
    builder.addCase(isKitchIn.fulfilled, (state, action) => {
      state.isKitchIn = action.payload;
    });
  },
});
