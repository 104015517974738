import { useSelector, useDispatch } from 'react-redux';
import AlertMessage from './alertMessage';
import { useEffect } from 'react';
import { alertsSlice } from '../../services/redux-toolkit/slices/alertsSlice';

export const ComponentWrapper = ({ children }) => {
  const success = useSelector((state) => state.alertsSlice.success);
  const error = useSelector((state) => state.alertsSlice.error);
  const message = useSelector((state) => state.alertsSlice.message);
  const code = useSelector((state) => state.alertsSlice.code);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(alertsSlice.actions.resetError());
      }, 1800);
    }
    if (success) {
      setTimeout(() => {
        dispatch(alertsSlice.actions.resetSuccess());
      }, 1800);
    }
  }, [error, success]);

  return (
    <>
      {success && <AlertMessage alertType={'success'} />}
      {error && (
        <AlertMessage alertType={'error'} alertMessage={message} code={code} />
      )}
      {children}
    </>
  );
};
