import {
  Box,
  Menu,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Typography,
  Avatar,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import style from '../../../styles/headFooter.module.css';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  kitchenSlice,
  permissions,
  isKitchIn,
} from '../../../services/redux-toolkit/slices/kitchenSlice';
import Score from '../score';
import Link from 'next/link';
export const HeaderSettingsMenu = ({
  anchorEl,
  setAnchorEl,
  list = [],
  position = {},
}) => {
  const { t } = useTranslation('common');
  const Router = useRouter();
  const user = useSelector((state) => state.userSlice.info);
  const dispatch = useDispatch();

  const hasDHAccess =
    user?.profile?.is_kitchefy_admin ||
    user?.profile?.is_fulfillment_partner ||
    user?.profile?.is_brand_owner ||
    user?.profile?.is_sampler;

  const platform = Router.asPath.startsWith('/quality-management')
    ? 'QM'
    : 'admin';

  const kitchen =
    useSelector((state) => state.kitchenSlice.currentKitchen) || '';
  const pos = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: Router.locale === 'ar' ? 'left' : 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: Router.locale === 'ar' ? 'left' : 'right',
    },
  };
  const handleChange = (event) => {
    dispatch(kitchenSlice.actions.selectedKitchen(event.target.value));
    dispatch(permissions());
    dispatch(isKitchIn());
    localStorage.setItem('currentKitchen', event.target.value);
    setAnchorEl(null);
    if (Router.asPath.startsWith('/quality-management/incidents/')) {
      Router.push('/quality-management/incidents');
    } else if (Router.asPath.startsWith('/quality-management/checklists/')) {
      Router.push('/quality-management/checklists');
    } else if (Router.asPath.startsWith('/quality-management/kitchios/')) {
      Router.push('/quality-management/kitchios');
    } else Router.push(Router.pathname);
  };
  const close = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    close();
    localStorage.removeItem('currentKitchen');
    Cookies.remove('accessToken', { path: '/', secure: true });
    Cookies.remove('refreshToken', {
      path: '/',
      secure: true,
      expires: 365,
    });
    Cookies.remove('type', { path: '/', secure: true, expires: 365 });
    Cookies.remove('QM', { path: '/', secure: true, expires: 365 });
    Router.push('/login');
  };
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      disableScrollLock={true}
      onClose={close}
      sx={{ padding: '0px' }}
      MenuListProps={{
        sx: {
          backgroundColor: `${
            platform == 'admin' ? 'admin.headerColor' : 'brand.secondaryDark'
          }`,
          boxShadow: 'none',
          minWidth: { xs: '90vw', md: '230px' },
        },
      }}
      {...pos}
      {...position}
    >
      <Box>
        <Box sx={{ px: '1rem', my: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Typography
              sx={{
                color: 'grey.white',
                fontSize: '1rem',
              }}
            >
              {user?.first_name} {user?.last_name}
            </Typography>
            <Box sx={{ marginRight: '10px' }}>
              {platform == 'QM' && (
                <Score score={user?.statistics?.score} color='#EDF6F9' />
              )}
            </Box>
          </Box>
          <Typography sx={{ color: 'grey.white', fontSize: '0.75rem' }}>
            {user?.email}
          </Typography>
        </Box>
        {list.length > 0 && (
          <MenuItem sx={{ p: 2 }}>
            <FormControl
              id='KitchensSelect'
              fullWidth
              required
              sx={{ color: 'grey.white' }}
            >
              <InputLabel id='kitchenId' sx={{ color: 'grey.white' }}>
                {t('kitchens')}
              </InputLabel>
              <Select
                labelId='kitchenId'
                value={kitchen}
                label={t('kitchens')}
                onChange={handleChange}
                sx={{
                  '&& fieldset': {
                    borderColor: 'grey.white',
                  },
                  '&:hover': {
                    '&& fieldset': {
                      borderColor: 'grey.white',
                    },
                  },
                }}
                inputProps={{
                  sx: {
                    color: 'grey.white',
                  },
                }}
                MenuProps={{
                  MenuListProps: {
                    sx: {
                      backgroundColor: 'brand.secondaryDark',
                      color: 'grey.white',
                    },
                  },
                }}
              >
                {list?.map((item) => (
                  <MenuItem value={item?.id} key={item?.id}>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                    >
                      <Avatar src={item?.value} sx={{ width: 24, height: 24 }}>
                        <Typography sx={{ fontSize: '12px' }}>
                          {item.name.slice(0, 1)}
                        </Typography>
                      </Avatar>
                      {item.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MenuItem>
        )}
        {user?.profile?.has_profile_kitchens && hasDHAccess && (
          <Link
            href={
              Router.asPath.startsWith('/quality-management')
                ? '/'
                : '/quality-management'
            }
          >
            <MenuItem sx={{ py: 2 }} onClick={close}>
              <Typography sx={{ color: 'grey.white' }}>
                {Router.asPath.startsWith('/quality-management')
                  ? 'Kitchefy Admin'
                  : 'Quality Management'}
              </Typography>
            </MenuItem>
          </Link>
        )}
        <MenuItem
          onClick={() => {
            handleLogout();
          }}
          sx={{ py: 2 }}
        >
          <Typography
            className={style.headFont}
            textAlign='center'
            sx={{ color: 'grey.white' }}
          >
            {t('authentication.Logout')}
          </Typography>
        </MenuItem>
      </Box>
    </Menu>
  );
};
