import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  success: false,
  error: false,
  message: null,
  code: null,
};

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    triggerSuccess(state) {
      state.success = true;
    },
    resetSuccess(state) {
      state.success = false;
    },
    triggerError(state) {
      state.error = true;
    },
    resetError(state) {
      state.error = false;
    },
    errorWithMessage(state, action) {
      state.error = action.payload?.error;
      state.message = action.payload?.message;
      state.code = action.payload?.code;
    },
  },
});
